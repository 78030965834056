<template>
<div class="winBox">
    <div class="win" :style="{
            width:width=='auto'?'auto':width+'px',
        }">
        <div class="winHeader">
            {{ title }}
            <div class="close" @click="close">
                <el-button type="text" icon="el-icon-close" style="font-size:18px;color:#606266"></el-button>
            </div>
        </div>
        <div class="winBody">
            <slot></slot>
        </div>
        <div class="winFooter" v-if="isBtn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button type="primary" size="small" @click="enter">确定</el-button>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data: function() {
        return {
            //title:'窗口标题'
        };
    },

    props: {
        title: String,
        width: {
            default: 700
        },
        isBtn: {
            type: Boolean,
            default: true,
        },
    },

    mounted: function() {

    },

    methods: {
        close: function() {
            this.$emit("winClose");
        },

        enter: function() {
            this.$emit("winEnter");
        }
    }
};
</script>

<style>
.winBox {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.winBox .win {
    background: #fff;
    width: 700px;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    overflow: hidden;
}

.winBox .win .winHeader {
    background: #f8f8f8;
    /* border-bottom: 1px solid #eeeeee; */
    height: 42px;
    line-height: 42px;
    position: relative;
    padding: 4px 15px;
}

.winBox .win .winHeader .close {
    width: 30px;
    height: 30px;
    border: 0;
    border-radius: 50%;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 4px;
    cursor: pointer;
}

.winBox .win .winHeader .close button i:hover {
    color: #409EFF;
}

.winBox .win .winBody {
    flex-grow: 1;
    overflow-y: auto;
    padding: 15px;
}

.winBox .win .winFooter {
    /* background: #f8f8f8; */
    /* border-bottom: 1px solid #eeeeee; */
    height: 42px;
    line-height: 42px;
	margin-bottom: 10px;
    position: relative;
    padding: 0 15px;
    text-align: right;
}
</style>
