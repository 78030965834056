<template lang="">
<div class="settingContent">
    <div class="handleBox flex flex-ai-c">
        <el-button style="margin-right:20px" size="small" icon="el-icon-back" @click="$router.go(-1)" v-if="$route.query.id">返回</el-button>
        <h3>{{$route.name}} <span v-if="$route.query.realname">（{{$route.query.realname}}）</span> </h3>
        <el-button style="margin-left:auto" type="primary" size="small" @click="handleEdit()">新建</el-button>
    </div>

    <div class="contentBox" style="width:100%">
        <el-table :data="list" height="100%">
            <el-table-column label="序号" width="100" align="center" type="index"> </el-table-column>
            <el-table-column prop="name" label="名称" align="center">
            </el-table-column>
            <el-table-column prop="vip_is" label="是否必选" align="center">
                <template slot-scope="scope">
                    {{scope.row.vip_is==0?'否':'是'}}
                </template>
            </el-table-column>
			<el-table-column prop="class_content" label="描述" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button @click="handleEdit(scope.row)" :disabled="$root.userInfo.admin_is==0&&scope.row.add_status==2" type="text">编辑</el-button>
                    <el-button @click="handleDel(scope.row)" :disabled="$root.userInfo.admin_is==0&&scope.row.add_status==2" type="text">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>


    <!-- 编辑弹框 -->
    <win :title="title" v-if="winIsShow" @winClose="winIsShow=false" @winEnter="submit">
        <el-form ref="edit" :rules="rules" :model="formData" label-width="120px">
            <el-form-item label="岗位职责名称" prop="name">
                <el-input v-model="formData.name" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="是否必选" prop="vip_is">
                <el-select v-model="formData.vip_is" placeholder="请选择" style="width:100%">
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                </el-select>
            </el-form-item>
			<el-form-item label="岗位职责描述">
                <el-input v-model="formData.class_content" placeholder="请输入"></el-input>
            </el-form-item>
        </el-form>
    </win>
</div>
</template>
<script>
import win from "../../components/win.vue";
export default {
    components: {
        win
    },
    data() {
        return {
            list: [],
            title: '新建岗位职责',
            winIsShow: false,
            formData: {

            },
            rules: {
                name: [{
                    required: true,
                    message: '请填写名称',
                    trigger: 'change'
                }],
                vip_is: [{
                    required: true,
                    message: '请选择',
                    trigger: 'change'
                }],
            },
        };
    },
    mounted() {
        this.getList()
    },
    methods: {
        handleEdit(row) {
            if (row) {
                this.title = '编辑岗位职责';
                row.vip_is = String(row.vip_is);
                this.formData = JSON.parse(JSON.stringify(row))
            } else {
                this.title = '新建岗位职责';
                this.formData = {}
            }
            this.winIsShow = true;
        },
        getList() {
            let param = {
                curr: 1,
                row: 10000,
            }
            if (this.$route.query.id) param.uid = this.$route.query.id;
            this.http.post('/admin.workClass/index', param).then(re => {
                this.list = re.list
            })
        },
        handleDel(row) {
            this.$confirm('确定删除选中行吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let url = '/admin.workClass/delete'
                this.http.post(url, {
                    id: row.id
                }).then(re => {
                    this.getList();
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                })
            })
        },
        submit() {
            this.$refs['edit'].validate((valid) => {
                if (valid) {
                    var apiUrl = "/admin.workClass/save";
                    if (this.$route.query.id) this.formData.uid = this.$route.query.id;
                    this.http.post(apiUrl, this.formData).then(res => {
                        this.winIsShow = false;
                        this.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                        this.getList();
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    },
};
</script>
